import "lightgallery.js";

lightGallery(document.getElementById("lightgallery"), {
  counter: false,
  nextHtml:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.356 14.113"><g transform="translate(-1271.129 -830)"><rect width="28" height="2" transform="translate(1271.129 836)"/><rect width="10" height="2" transform="translate(1294.414 830) rotate(45)"/><rect width="10" height="2" transform="translate(1293 842.698) rotate(-45)"/></g></svg>',
  prevHtml:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.356 14.113"><g transform="translate(1301.485 844.112) rotate(180)"><rect width="28" height="2" transform="translate(1271.129 836)"/><rect width="10" height="2" transform="translate(1294.414 830) rotate(45)"/><rect width="10" height="2" transform="translate(1293 842.698) rotate(-45)"/></g></svg>',
  download: false
});
